import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ProblemService} from '../modules/problem/service/problem.service';
import {ProblemResult} from '../modules/problem/model/problem-result';

@Injectable({ providedIn: 'root' })
export class ProblemResultResolve implements Resolve<ProblemResult> {

  constructor(private service: ProblemService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProblemResult> | Promise<ProblemResult> | ProblemResult {
    return this.service.getResults(route.params.id);
  }
}
