import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProblemFormComponent} from './component/problem-form/problem-form.component';
import {ProblemEditPageComponent} from './page/problem-edit/problem-edit-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {ProblemTestFormComponent} from './component/problem-test-form/problem-test-form.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {ParseServerModule} from '../parse-server/parse-server.module';
import {ProblemViewComponent} from './page/problem-view/problem-view.component';
import {ProblemListResolve} from '../../resolve/problem-list.resolve';
import {ProblemListComponent} from './page/problem-list/problem-list.component';
import {RouterModule} from '@angular/router';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {MatIconModule} from '@angular/material/icon';
import {MessageDialogComponent} from './component/message-dialog/message-dialog.component';
import {ProblemResponseComponent} from './page/problem-response/problem-response.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ProblemResultComponent} from './page/problem-result/problem-result.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ChipListModule} from '../chip-list/chip-list.module';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/python/python';
import {ProblemMyResultComponent} from './page/problem-my-result/problem-my-result.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmDialogModule} from '../confirm-dialog/confirm-dialog.module';
import {ProblemFnArgFormComponent} from "./component/problem-fn-arg-form/problem-fn-arg-form.component";

@NgModule({
  declarations: [
    ProblemFormComponent,
    ProblemEditPageComponent,
    ProblemFnArgFormComponent,
    ProblemTestFormComponent,
    ProblemViewComponent,
    ProblemListComponent,
    MessageDialogComponent,
    ProblemResponseComponent,
    DashboardComponent,
    ProblemResultComponent,
    ProblemMyResultComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    ParseServerModule,
    RouterModule,
    CodemirrorModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    ChipListModule,
    MatDialogModule,
    ConfirmDialogModule
  ],
  providers: [
    ProblemListResolve
  ]
})
export class ProblemModule {
}
