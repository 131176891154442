import * as Parse from 'parse';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable()
export class ParseServerService {

  constructor(private http: HttpClient) {
    Parse.initialize(environment.parse.applicationId);
    // @ts-ignore
    Parse.masterKey = 'secret';
    // @ts-ignore
    Parse.serverURL = '/api/parse';
    if (typeof window !== `undefined`) {
      // guard for server side rendering
      const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      // @ts-ignore
      Parse.liveQueryServerURL = `${wsProtocol}://${window.location.host}/api/`;
    }
  }

  public query(objectClass: string): Parse.Query {
    return new Parse.Query(objectClass);
  }

  public object(klass: string): Parse.Object {
    return new Parse.Object(klass);
  }

  public run(func: string, params: any): Observable<any> {
    const currentUser = Parse.User.current();
    if (currentUser) {
      return fromPromise(Parse.Cloud.run(func, params));
    } else {
      // show the signup or login page
    }
  }

  public login(user, password) {
    return fromPromise(Parse.User.logIn(user, password));
  }

  public logout() {
    Parse.User.logOut().then();
  }

  public signup(username: string, password: string, email: string) {
    return fromPromise(Parse.User.signUp(username, password, {'email': email}));
  }

  public getCurrentUser() {
    return Parse.User.current();
  }

  public async getUsersByIds(userIds: string[]) {
    const query = new Parse.Query(Parse.User);
    query.limit(1000)
    query.containedIn("objectId", userIds);
    return await query.find();
  }

  public async getSolution(problemId: string, userId: string) {
    const query = new Parse.Query(Parse.User);
    return await query.get(userId);
  }

  public isUnusedUsername(username: string) {
    // console.log("ParseServerService: Checking if " + username + " is used");
    const query = new Parse.Query(Parse.User);
    query.equalTo('username', username);
    return fromPromise(query.count({ useMasterKey: true }));
  }

  public isUnusedEmail(email: string) {
    //c onsole.log("ParseServerService: Checking if " + email + " is used");
    const query = new Parse.Query(Parse.User);
    query.equalTo('email', email);
    return fromPromise(query.count({ useMasterKey: true }));
  }

  public sendResetPassword(email: string) {
    Parse.User.requestPasswordReset(email).then(function(){
      alert("Password reset sent to " + email + "! Please check your inbox (and spam folder)");
    });
  }

}
