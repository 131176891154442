import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProblemService } from '../../service/problem.service';
import { Problem } from '../../model/problem';
import { ActivatedRoute, Router } from '@angular/router';
import { JobeService } from '../../../parse-server/service/jobe.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../../component/message-dialog/message-dialog.component';
import { RESULT_STATUS, resultStatus } from '../../Constants';
import { ProblemTest, ProblemTestResult } from '../../model/problem-test';
import { FormControl, FormGroup } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog/confirm-dialog.component';
import { EMPTY, of } from 'rxjs';
import { ParseUtils } from '../../../parse-server/utils/parse-utils';

@Component({
  selector: 'app-problem-view',
  templateUrl: './problem-view.component.html',
  styleUrls: ['./problem-view.component.scss']
})
export class ProblemViewComponent implements OnInit {

  @ViewChild('codeMirror', { static: false, read: ElementRef}) codeMirror!: ElementRef;

  public value: Problem;

  testResults: { [id: string]: ProblemTestResult } = {};
  formGroup: FormGroup = new FormGroup({
    languageId: new FormControl(),
    sourceCode: new FormControl()
  });

  POSITIVE_INFINITY = Infinity;

  get visibleTests() {
    return this.value ? this.value.tests.filter(test => test.visible) : [];
  }

  constructor(
    private service: ProblemService,
    private jobe: JobeService,
    private route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(result => {
      this.value = result.problem as Problem;
    });

    this.formGroup.get('languageId').valueChanges.pipe(
      switchMap((current) => {
        const oldLanguageId = this.formGroup.value.languageId;
        if (this.formGroup.value.languageId) {
          return this.dialog.open(ConfirmDialogComponent, {
            data: {
              toReturn: current,
              title: 'Changing language',
              content: 'Code will be reset, are you sure to proceed?'
            }
          }).afterClosed().pipe(
            switchMap(result => {
              if (!result) {
                this.formGroup.get('languageId').setValue(oldLanguageId, {emitEvent: false});
                return EMPTY;
              }
              return of(current);
            })
          );
        } else {
          return of(current);
        }
      })
    ).subscribe((language) => {
      if (language) {
        this.formGroup.get('sourceCode').setValue(
          this.value.templates[language], {emitEvent: false});
      }
    });
  }

  runTest(id: string = null) {
    const saveResult = result => {
      result.tests.map(test => {
        // tslint:disable-next-line:no-shadowed-variable
        const {id, success, stdout, stderr} = test;
        const found = this.value.tests.find(elm => elm.id === id);
        found.success = success;
        this.testResults[id] = {stdout, stderr};
      });
    };
    const reset = it => {
      delete it.success;
      this.testResults[it.id] = {pending: true};
    };
    if (id) {
      this.value.tests.filter(it => it.id === id).forEach(reset);
      this.service.runTest(
        this.value.problemId, this.formGroup.get('languageId').value, this.formGroup.get('sourceCode').value, id
      ).subscribe(saveResult);
    } else {
      this.value.tests.forEach(reset);
      this.service.runVisibleTests(
        this.value.problemId, this.formGroup.get('languageId').value, this.formGroup.get('sourceCode').value
      ).subscribe(saveResult);
    }
  }

  confirm() {
    this.service.runAllTests(
      this.value.problemId, this.formGroup.get('languageId').value, this.formGroup.get('sourceCode').value
    ).subscribe(result => {
      const failedTests = result.outcome.failed;
      // const failedTests = result.tests.filter(test => !test.success);
      if (failedTests === 0) {
        this.router.navigate(['problems/solved']);
      } else {
        this.alertFailed(result.outcome.tests);
      }
    });
  }

  alertFailed(tests) {
    this.dialog.open(MessageDialogComponent,{
      data: {
        title: 'Attenzione',
        content: 'Non hai passato uno o più Test',
        tests
      }
    });
  }

  getMode = ParseUtils.parseLanguageId;

  getRunIcon(test: ProblemTest) {
    return this.testResults[test.id]?.pending ? RESULT_STATUS.pending : '▶';
  }

  getSuccessIcon(test: ProblemTest) {
    return resultStatus(test.success);
  }

  public doFullScreen() {
    console.log(this.codeMirror);
    this.codeMirror.nativeElement.requestFullscreen();
  }
}
