<div [formGroup]="formGroup" class="row">
  <div class="col-md-4">
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
  </div>
  <div class="col-md-2 d-flex align-items-center">
    <button mat-icon-button color="warn" (click)="onDelete()" matTooltip="Remove argument">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>

