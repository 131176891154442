import {Component, OnInit} from '@angular/core';
import {ProblemService} from '../../service/problem.service';
import {UserService} from '../../../user/service/user.service';
import {resultStatus} from '../../Constants';

@Component({
  selector: 'app-problem-my-result',
  templateUrl: './problem-my-result.component.html',
  styleUrls: ['./problem-my-result.component.scss']
})
export class ProblemMyResultComponent implements OnInit {

  private _currentUser;

  private _problems = [];

  get problems() {
    return this._problems;
  }

  private _badges;

  get badges() {
    return this._badges;
  }

  constructor(private service: ProblemService,
              private userService: UserService) { }

  ngOnInit(): void {
    this._currentUser = this.userService.getCurrentUser();

    this.service.getUserResults(this._currentUser.id).subscribe(result => {
      this._problems = result.problems;
      this._badges = result.badges;
    });
  }

  getLanguageIcon(type: string): string{
    switch (type){
      case 'java': return 'devicon-java-plain javaColor';
      case 'nodejs': return 'devicon-javascript-plain javascriptColor';
      case 'python3': return 'devicon-python-plain pythonColor';
      default: return 'devicon-linux-plain';
    }
  }

  getSuccessIcon = resultStatus;
}
