import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobeService {

  constructor(
    private http: HttpClient
  ) {
  }

  public runAll(language: string, problemId: string, sourceCode: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('X-Parse-Application-Id', 'i3sapiens')
      .set('Content-Type', 'application/json')
      .set('X-Parse-Master-Key', 'secret');
    return this.http.post(
      '/parse/functions/runAll',
      {language, problemId, sourceCode},
      { headers }
    );
  }
}
