<div class="col-md-12"><h1>I tuoi badge</h1></div>
<mat-card class="col-md-6">
  <div *ngFor="let b of badges" class="row">
    <div class="col-md-2 my-2 text-nowrap">
      <i [matTooltip]="b.name" class="material-icons badgeIcon" [style]="'color: ' + b.color">{{b.icon}}</i>
      <i *ngIf="b.criteria[0].language" [matTooltip]="b.name" class="badgeLanguage {{getLanguageIcon(b.criteria[0].language)}}"></i>
    </div>
    <div class="col-md-2 my-2">
      <div>{{b.name}}</div>
    </div>
    <div class="col-md-8 my-2">
      {{b.description}}
    </div>
  </div>
  <a href="/badges">Vedi tutti i badge</a>&nbsp;|&nbsp;<a href="/usersBadges">Hall of fame</a>
</mat-card>

<br/>
<br/>

<div class="col-md-12"><h1>I tuoi problemi</h1></div>
<mat-card class="col-md-6">
  <div *ngFor="let p of problems" class="row">
    <div class="col-md-6 my-2">{{p.problem.name}}</div>
    <div class="col-md-3 my-2 ok-icon">{{getSuccessIcon(p.solved)}}</div>
    <div class="col-md-3 ">
      <div class="d-flex justify-content-center">
        <mat-icon *ngIf="p.solved"
                  [routerLink]="'/problems/results/' + p.problem.problemId"
                  class="header-icon cursor-pointer"
                  matTooltip="Who solved it?">group</mat-icon>
        <button *ngIf="!p.solved"
                [routerLink]="'/problems/view/' + p.problem.problemId"
                class="flex-grow-1"
                mat-raised-button color="primary">
          <span *ngIf="p.total <= 0">Try</span>
          <span *ngIf="p.total > 0">Try again</span>
        </button>
      </div>
    </div>
  </div>
</mat-card>
