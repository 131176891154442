import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Problem} from '../../model/problem';
import {ProblemTest} from '../../model/problem-test';
import {LANGUAGE_MODES} from '../../Constants';
import {ProblemFnArg} from '../../model/problem-fn-arg';

function validateFnArgsSize(arr: FormArray) {
  return arr.length < 1 ? {
    invalidSize: true
  } : null;
}

function validateTestsSize(arr: FormArray) {
  return arr.length < 3 ? {
    invalidSize: true
  } : null;
}

@Component({
  selector: 'app-problem-form',
  templateUrl: './problem-form.component.html',
  styleUrls: ['./problem-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProblemFormComponent),
      multi: true
    }
  ]
})
export class ProblemFormComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public formGroup: FormGroup = new FormGroup({
    objectId: new FormControl(),
    problemId: new FormControl(),
    createdAt: new FormControl(),
    updatedAt: new FormControl(),
    name: new FormControl(''),
    subject: new FormControl(''),
    level: new FormControl(),
    tags: new FormControl([]),
    preview: new FormControl(),
    body: new FormControl(),
    template: new FormControl(),
    fnArgs: new FormArray([], validateFnArgsSize),
    tests: new FormArray([], validateTestsSize)
  });

  private _subscriptions: Subscription[] = [];

  public tagChoices: string[] = ['python', 'java', 'problem'];

  constructor() {
  }

  private _onChange(value: Problem) {
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this.formGroup.get('subject').valueChanges.subscribe(value => {
        this.formGroup.get('template').patchValue(''); // TODO: check Gabor
      }),
      this.formGroup.valueChanges.subscribe(value => {
        if (this.formGroup.valid) {
          this._onChange(this.formGroup.getRawValue());
        } else {
          this._onChange(null);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  addFnArg(fnArg: ProblemFnArg = null) {
    if (!fnArg) {
      fnArg = !!fnArg ? fnArg : {
        id: Math.random().toString(36).substring(4),
        name: ''
      };
    }
    (this.formGroup.get('fnArgs') as FormArray).push(new FormControl(fnArg));
    (this.formGroup.get('tests') as FormArray).controls.forEach(item => {
      const oldValue = Object.assign({}, item.value);
      oldValue.input.push('');

      item.setValue(oldValue);
    });
  }

  removeFnArg(index: number) {
    (this.formGroup.get('fnArgs') as FormArray).removeAt(index);
    (this.formGroup.get('tests') as FormArray).controls.forEach(item => {
      const oldValue = Object.assign({}, item.value);
      item.value.input.splice(index, 1);

      item.setValue(oldValue);
    });
  }

  addTest(test: ProblemTest = null) {
    const test1 = !!test ? test : {
      id: Math.random().toString(36).substring(4),
      name: '',
      input: [],
      output: '',
      visible: false
    };
    if (null === test) {
      (this.formGroup.get('fnArgs') as FormArray).controls.forEach(_ => {
        test1.input.push('');
      });
    }
    (this.formGroup.get('tests') as FormArray).push(new FormControl(test1));
  }

  removeTest(index: number) {
    (this.formGroup.get('tests') as FormArray).removeAt(index);
  }

  trackByFnArg(index: number, item: any) {
    return index;
  }

  trackByFnTest(index: number, item: any) {
    return index;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  writeValue(value: Problem): void {
    if (value) {
      this.formGroup.patchValue(value, {emitEvent: false});
      (this.formGroup.get('fnArgs') as FormArray).clear();
      value.fnArgs.forEach(fnArg => {
        this.addFnArg(fnArg);
      });
      (this.formGroup.get('tests') as FormArray).clear();
      value.tests.forEach(test => {
        this.addTest(test);
      });
    }
    if (this.formGroup.invalid) {
      this._onChange(null);
    }
  }

  public displayError(controlName: string, errorKey: string): boolean {
    const fc: AbstractControl = this.formGroup.get(controlName);
    if (
      fc.touched &&
      fc.invalid &&
      fc.errors
    ) {
      return fc.errors[errorKey];
    }
    return null;
  }

}
