import {ParseServerService} from './service/parse-server.service';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {map, switchMap} from 'rxjs/operators';
import {AbsParseObject} from './abs-parse-object';
import {LoaderService} from '../loader/service/loader.service';

export abstract class ParseApi<T extends AbsParseObject> {
  protected abstract _parseClass: string;

  protected constructor(
    protected parse: ParseServerService,
    protected loader: LoaderService
  ) {
  }


  get _query(): Parse.Query {
    return this.parse.query(this._parseClass);
  }

  protected _findAll(filter: string): Observable<Parse.Object[]> {
    const query = this._query;
    if (filter) {
      query.matches('name', new RegExp(filter, 'i'));
    }
    return this.loader.registerLoader<Parse.Object[]>(
      fromPromise<Parse.Object[]>(
        query.find() as Promise<Parse.Object[]>
      )
    );
  }

  public findAll(filter: string = null): Observable<T[]> {
    return this._findAll(filter).pipe(
      map((objs) => {
        return objs.map(object => this._parseObjectToJson(object));
      })
    );
  }

  protected _find(id: string): Observable<Parse.Object> {
    const query = this._query.equalTo('problemId', id);
    return this.loader.registerLoader<Parse.Object>(
      fromPromise<Parse.Object>(query.first() as Promise<Parse.Object>)
    );
  }

  public find(id: string): Observable<T> {
    return this._find(id).pipe(
      map(object => {
        return this._parseObjectToJson(object);
      })
    );
  }

  protected _insert(obj: T): Observable<Parse.Object> {
    const object = this.parse.object(this._parseClass);
    Object.keys(obj).forEach(key => {
      object.set(key, obj[key]);
    });
    return this.loader.registerLoader<Parse.Object>(
      fromPromise<Parse.Object>(object.save()).pipe(
        switchMap(result => this._find(result.id))
      ), 'Inserted successfully'
    );
  }

  public insert(obj: T): Observable<T> {
    return this._insert(obj).pipe(
      map(object => {
        return this._parseObjectToJson(object);
      })
    );
  }

  protected _update(id: string, obj: T): Observable<Parse.Object> {
    return this.loader.registerLoader<Parse.Object>(
      this._find(id).pipe(
        switchMap(result => {
          Object.keys(obj).forEach(key => {
            result.set(key, obj[key]);
          });
          return fromPromise(result.save());
        }),
        switchMap(_ => this._find(id))
      ), 'Updated successfully'
    );
  }

  public update(id: string, obj: T): Observable<T> {
    return this._update(id, obj).pipe(
      map(object => this._parseObjectToJson(object))
    );
  }

  protected _parseObjectToJson(obj: Parse.Object): T {
    return Object.assign({}, obj.attributes, {objectId: obj.id}) as T;
  }
}
