import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProblemService} from '../../service/problem.service';
import {Problem} from '../../model/problem';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-problem-edit',
  templateUrl: './problem-edit-page.component.html',
  styleUrls: ['./problem-edit-page.component.scss']
})
export class ProblemEditPageComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({
    problem: new FormControl('', Validators.required)
  });

  private _value: Problem = null;

  constructor(
    private service: ProblemService,
    private route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(result => {
      this._value = result.problem as Problem;
      this.formGroup.get('problem').setValue(this._value, {emitEvent: false});
    });
    this.formGroup.get('problem').valueChanges.subscribe(result => {
      this._value = result;
    });
  }

  saveProblem() {
    if (this.formGroup.valid) {
      this.service.insert(this.formGroup.getRawValue().problem).subscribe(result => {
        // this.formGroup.get('problem').setValue(result, {emitEvent: false});
        if (result) {
          this.router.navigate(['problems']);
        }
      });
    }
  }
}
