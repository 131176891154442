import {Component, forwardRef, Input, OnChanges, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipListComponent),
      multi: true
    }
  ]
})
export class ChipListComponent implements OnInit, ControlValueAccessor {

  @Input()
  choices: string[] = [];

  tagsInputCtrl = new FormControl();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  public tagList: string[] = [];

  filteredChoices: Observable<string[]>;

  constructor() { }

  ngOnInit(): void {
    this.filteredChoices = this.tagsInputCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.choices.slice()));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: string[]): void {
    this.tagList = obj || [];
  }

  onChange(value: string[]) {
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tagList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagsInputCtrl.setValue(null);

    this.onChange(this.tagList);
  }

  remove(fruit: string): void {
    const index = this.tagList.indexOf(fruit);

    if (index >= 0) {
      this.tagList.splice(index, 1);
    }

    this.onChange(this.tagList);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tagList.push(event.option.viewValue);
    // this.fruitInput.nativeElement.value = '';
    this.tagsInputCtrl.setValue(null);

    this.onChange(this.tagList);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.choices.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

}
