<div [formGroup]="formGroup" class="row">
  <div class="col-md-2">
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
  </div>
  <ng-container formArrayName="input">
    <div class="col-md-1" *ngFor="let arg of items(); let i = index; trackBy:trackByIndex">
      <mat-form-field class="full-width">
        <mat-label>Input</mat-label>
        <input matInput [formControlName]="i">
      </mat-form-field>
    </div>
  </ng-container>
  <div class="col-md-1">
    <mat-form-field class="full-width">
      <mat-label>Output</mat-label>
      <input matInput formControlName="output">
    </mat-form-field>
  </div>
  <div class="col-md-1 align-items-center">
    <mat-checkbox formControlName="visible">Visible</mat-checkbox>
  </div>
  <div class="col-md-1 align-items-center">
    <button mat-icon-button color="warn" (click)="onDelete()" matTooltip="Remove test">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
