import {Component, OnInit} from '@angular/core';
import {BadgeService} from '../../service/badge.service';

@Component({
  selector: 'app-badges',
  templateUrl: './users-badges.component.html',
  styleUrls: ['./users-badges.component.scss']
})
export class UsersBadgesComponent implements OnInit {
  private _usersBadges;

  get usersBadges() {
    return this._usersBadges;
  }

  constructor(private service: BadgeService) {
  }



  ngOnInit(): void {
    this.service.getUsersBadges().subscribe(usersBadges => {
      this._usersBadges = usersBadges;
    });
  }

  getLanguageIcon(type: string): string {
    switch (type) {
      case 'java':
        return 'devicon-java-plain javaColor';
      case 'nodejs':
        return 'devicon-javascript-plain javascriptColor';
      case 'python3':
        return 'devicon-python-plain pythonColor';
      default:
        return 'devicon-linux-plain';
    }
  }
}
