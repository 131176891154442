import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../modules/user/service/user.service';
import {Router} from '@angular/router';
import {ParseServerService} from 'src/app/modules/parse-server/service/parse-server.service';
import {LoaderService} from '../../modules/loader/service/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './app-login.component.html',
  styleUrls: ['./app-login.component.scss']
})
export class AppLoginComponent implements OnInit {

  public formGroup: FormGroup = new FormGroup({
    user: new FormControl(),
    password: new FormControl(),
    emailreset: new FormControl()
  });

  public showErrorMessage = false;

  constructor(private service: UserService,
              private parseService: ParseServerService,
              private loader: LoaderService,
              private route: Router) {
  }

  public login() {
    this.loader.registerLoader( this.service.login(this.formGroup.get('user').value, this.formGroup.get('password').value))
      .subscribe(_ => {
        this.showErrorMessage = false;
        this.route.navigateByUrl('/problems');
      }, error => {
        console.log(error);
        this.showErrorMessage = true;
      });
  }

  public signup() {
    this.route.navigateByUrl('/signup');
  }

  public passwordreset() {
    this.parseService.sendResetPassword(this.formGroup.get('emailreset').value);
  }


  ngOnInit(): void {
  }


}
