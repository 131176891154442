import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ProblemService } from "../../service/problem.service";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { resultStatus } from "../../Constants";
import { MatDialog } from "@angular/material/dialog";
import { ParseUtils } from "../../../parse-server/utils/parse-utils";

@Component({
  selector: "app-problem-result",
  templateUrl: "./problem-result.component.html",
  styleUrls: ["./problem-result.component.scss"]
})
export class ProblemResultComponent implements OnInit {

  @ViewChild("resultDialog") resultDialogTemplate: TemplateRef<any>;

  private _results = [];
  private _count = 0;

  get results() {
    return this._results;
  }

  get count() {
    return this._count;
  }

  constructor(private route: ActivatedRoute,
              private service: ProblemService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(result => {
      const problemResult = result.problemResults;
      const userList = [];
      const solutionLanguages = problemResult.languages;

      const userIds = problemResult.byUser.map(u => u.userId);
      this._count = userIds.length;

      this.service.getUsersByIds(userIds).subscribe(users => {
        // @ts-ignore
        const idToUser = Object.fromEntries(users.map(u => [u.id, u]));
        problemResult.byUser.forEach(userResult => {
          const userId = userResult.userId;
          const user = idToUser[userId];
          userList.push({
            userId,
            name: user.getUsername(),
            lastSolvedData: this.getLastSolved(solutionLanguages),
            time: userResult.time,
            solved: userResult.solved,
            avatar: user.get("avatar"),
            solvedLanguages: userResult.solvedLanguages
          });
          userList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        });
      });
      this._results.push({
        problemId: problemResult.problem.id,
        name: problemResult.problem.name,
        languages: solutionLanguages,
        users: userList
      });
    });
  }

  private getLastSolved(solutionLanguages: any[]): any {
    return solutionLanguages.reduce((a, b) => (a.solvedDate > b.solvedDate ? a : b));
  }

  getFormatDate(date: string): string {
    return moment(date).fromNow();
  }

  getSuccessIcon(solved: boolean) {
    return resultStatus(solved);
  }

  seeSolution(problemId: string, languageId: string, userId: string) {
    this.service.getSolution(problemId, languageId, userId).subscribe(result => {
      if (!result.error) {
        const codice = result.result.sourceCode;
        const lang = ParseUtils.parseLanguageId(result.result.languageId);
        this.dialog.open(this.resultDialogTemplate, { data: { source: codice, language: lang, message: null } });
      } else {
        this.dialog.open(this.resultDialogTemplate, { data: { source: null, language: null, message: result.error } });
      }
    });
  }

  getIcon(type: string): string {
    switch (type) {
      case "java":
        return "devicon-java-plain javaColor";
      case "nodejs":
        return "devicon-javascript-plain javascriptColor";
      case "python3":
        return "devicon-python-plain pythonColor";
      default:
        return "devicon-linux-plain";
    }
  }
}
