import {Injectable} from '@angular/core';
import {ParseServerService} from '../../parse-server/service/parse-server.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService  {

  constructor(protected parse: ParseServerService, private route: Router) { }

  public login(user: string, password: string): Observable<any> {
    return this.parse.login(user, password);
  }

  public signup(user: string, password: string, email: string): Observable<any> {
    return this.parse.signup(user, password, email);
  }


  public getCurrentUser() {
    return this.parse.getCurrentUser();
  }

  public logout() {
    return this.parse.logout();
  }

  public getRole() {
    return this.parse.run('whoami', {});
  }

  public isUnusedUsername(username: string) {
    console.log("UserService: Checking if " + username + " is used");
    return this.parse.run('checkUniqueUsername', {username});
  }

  public isUnusedEmail(email: string) {
    console.log("Checking if " + email + " is used");
    return this.parse.run('checkUniqueEmail', {email});
  }
}
