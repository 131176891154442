// see https://codemirror.net/mode/ for other modes
export type AvailableLanguage = 'java'| 'nodejs'| 'python3';

export const LANGUAGE_MODES: Record<AvailableLanguage, string> = Object.freeze({
  java: "text/x-java",
  nodejs: "javascript",
  python3: "text/x-python"
});

export const RESULT_STATUS =  Object.freeze({
  ok: "👍",
  ko: "👹",
  pending: "⏲"
});

export const resultStatus = (b: boolean) => {
  if (b === true) {
    return RESULT_STATUS.ok;
  } else if (b === false) {
    return RESULT_STATUS.ko;
  } else {
    return "";
  }
};
