import {Injectable} from '@angular/core';
import {Problem} from '../model/problem';
import {ParseServerService} from '../../parse-server/service/parse-server.service';
import {ParseApi} from '../../parse-server/parse-api';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {LoaderService} from '../../loader/service/loader.service';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class ProblemService extends ParseApi<Problem> {

  protected _parseClass = 'Problems';

  constructor(
    protected parse: ParseServerService,
    protected loader: LoaderService
  ) {
    super(parse, loader);
  }

  public insert(obj: Problem): Observable<Problem> {
    return super.insert(obj).pipe(
      switchMap(result => {
        result.problemId = result.objectId;
        return this.update(result.objectId, result);
      })
    );
  }

  public findAll(filter: string = null): Observable<Problem[]> {
    return this.parse.run('problemsByFilter', {filter});
  }

  public find(id: string): Observable<Problem> {
    return this.parse.run('problemById', {id});
  }

  public runAllTests(id: string, languageId: string, sourceCode: string): Observable<any> {
    return this.loader.registerLoader(
      this.parse.run('solveProblem', {
            languageId,
            problemId: id,
            sourceCode
          })
    );
  }

  public runTest(id: string, languageId: string, sourceCode: string, testId: string): Observable<any> {
    return this.loader.registerLoader(
      this.parse.run('run', {
        languageId,
        problemId: id,
        sourceCode,
        testIds: [testId]
      })
    );
  }

  public runVisibleTests(id: string, languageId: string, sourceCode: string): Observable<any> {
    return this.loader.registerLoader(
      this.parse.run('runVisible', {
            languageId,
            problemId: id,
            sourceCode
          })
    );
  }

  public getUserResults(userId: string): Observable<any> {
    return this.parse.run('userResults', {userId});
  }

  public getResults(problemId: string): Observable<any> {
    return this.parse.run('results', {problemId});
  }

  public getUsersByIds(userIds: string[]): Observable<any> {
    return fromPromise(this.parse.getUsersByIds(userIds));
  }

  public getSolution(problemId: string, languageId: string, userId: string): Observable<any> {
    return this.parse.run('getSolution', {problemId, languageId, userId});
  }

}

