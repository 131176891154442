<mat-toolbar color="primary">
  <a href="/" class="headerLink">
    <img src="/assets/i3-monogram-white.png" alt="Made by Intré" width="30" height="30" />
    <span>Intr&eacute; Sapiens</span>
  </a>
  <div class="flex-grow"></div>
  <div>
    <button mat-button [routerLink]="'/user/myprofile/'" *ngIf="getCurrentUser()">My profile</button>
    <button mat-button [routerLink]="'/problems/myresults/'" *ngIf="getCurrentUser()">My results</button>
    <button mat-button (click)="logout()" *ngIf="getCurrentUser()">Logout</button>
    <button mat-button (click)="help()">❔</button>
  </div>
</mat-toolbar>
