import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../modules/user/service/user.service';
import {Router} from '@angular/router';
import {ParseServerService} from 'src/app/modules/parse-server/service/parse-server.service';

@Component({
  selector: 'app-app-signup',
  templateUrl: './app-signup.component.html',
  styleUrls: ['./app-signup.component.scss']
})

export class AppSignupComponent implements OnInit {

  public formGroup: FormGroup = new FormGroup({
    email:      new FormControl(),
    password:   new FormControl(),
    user:       new FormControl(),
    password2:  new FormControl()
  });

  public showErrorMessage = false;

  constructor(private service: UserService,
              private parseService: ParseServerService,
              private route: Router) {
  }

  public login() {
    this.route.navigateByUrl('/login');
  }

  public signup() {
    this.showErrorMessage = false;
    const password  = this.formGroup.get('password').value;
    const password2 = this.formGroup.get('password2').value;
    const username  = this.formGroup.get('user').value;
    const email     = this.formGroup.get('email').value;
    const passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');

    // Le password devono coincidere (devo verificare anche robustezza)
    if (password === password2 && password.match(passRegex)){

      // lo username non deve essere in uso
      if (username && username !== '' ){

        const checkUniqueUser = this.parseService.isUnusedUsername(username).subscribe(result => {
          if (!result) {
            // la mail non deve essere in uso
            const checkUniqueEmail = this.parseService.isUnusedEmail(email).subscribe(result2 => {
              if (!result2) {
                this.service.signup(username, password, email).subscribe(_ => {
                  this.service.logout();
                  alert(`Registrazione di ${username} (${email}) completata. Controlla la tua casella di posta per confermare la mail inserita.`);
                  this.route.navigateByUrl('/login');
                });
              } else {
                alert('Email già in uso!');
                this.showErrorMessage = true;
             }
            });
         } else {
           alert('Username già in uso!');
           this.showErrorMessage = true;
         }

       });
      }

    } else {
      alert('La password non è stata confermata correttamente o è troppo debole');
      this.showErrorMessage = true;
    }
  }

  ngOnInit(): void {
  }

}
