import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, delay, finalize, tap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _isLoadingRegistry = 0;

  public get isLoading(): boolean {
    return !!this._isLoadingRegistry;
  }

  constructor(
    private snackBar: MatSnackBar
  ) {
  }

  registerLoader<T>(obs: Observable<T>, successMessage: string = null): Observable<T> {
    this._isLoadingRegistry++;
    return obs.pipe(
      delay(400),
      tap(_ => {
        if (successMessage) {
          this.snackBar.open(successMessage, null, {
            duration: 1500
          });
        }
      }),
      catchError((error) => {
        this.snackBar.open(error, null, {
          duration: 5000
        });
        throw new Error(error.error);
      }),
      finalize(() => {
        this._isLoadingRegistry--;
      })
    );
  }
}
