import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProblemEditPageComponent} from './modules/problem/page/problem-edit/problem-edit-page.component';
import {PageNotFoundComponent} from './component/page-not-found/page-not-found.component';
import {AppLoginComponent} from './component/app-login/app-login.component';
import {ProblemViewComponent} from './modules/problem/page/problem-view/problem-view.component';
import {ProblemListComponent} from './modules/problem/page/problem-list/problem-list.component';
import {ProblemListResolve} from './resolve/problem-list.resolve';
import {ProblemResolve} from './resolve/problem.resolve';
import {ProblemResponseComponent} from './modules/problem/page/problem-response/problem-response.component';
import {DashboardComponent} from './modules/problem/component/dashboard/dashboard.component';
import {ProblemResultComponent} from './modules/problem/page/problem-result/problem-result.component';
import {AuthService} from './modules/user/service/auth.service';
import {AppSignupComponent} from './component/app-signup/app-signup.component';
import {ProblemMyResultComponent} from './modules/problem/page/problem-my-result/problem-my-result.component';
import {ProblemResultResolve} from './resolve/problem-result.resolve';
import {UserMyProfileComponent} from './modules/user/page/user-my-profile/user-my-profile.component';
import {BadgesComponent} from './modules/badge/page/badges/badges.component';
import {UsersBadgesComponent} from './modules/badge/page/users-badges/users-badges.component';


const routes: Routes = [
  { path: 'problems', component: ProblemListComponent, resolve: {problems: ProblemListResolve }, canActivate: [AuthService] },

  { path: 'problems/edit', component: ProblemEditPageComponent, canActivate: [AuthService] },
  { path: 'problems/edit/:id', component: ProblemEditPageComponent, resolve: { problem: ProblemResolve }, canActivate: [AuthService] },

  { path: 'problems/view/:id', component: ProblemViewComponent, resolve: { problem: ProblemResolve }, canActivate: [AuthService] },

  { path: 'problems/solved', component: ProblemResponseComponent, canActivate: [AuthService]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthService]},

  { path: 'problems/myresults', component: ProblemMyResultComponent, canActivate: [AuthService]},
  { path: 'problems/results/:id', component: ProblemResultComponent,
    resolve: { problemResults: ProblemResultResolve }, canActivate: [AuthService]},

  { path: 'user/myprofile', component: UserMyProfileComponent, canActivate: [AuthService]},

  { path: 'badges', component: BadgesComponent, canActivate: [AuthService]},
  { path: 'usersBadges', component: UsersBadgesComponent, canActivate: [AuthService]},

  { path: 'login', component: AppLoginComponent },
  { path: 'signup', component: AppSignupComponent },
  { path: '', redirectTo: '/problems', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
