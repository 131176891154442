import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Problem} from '../modules/problem/model/problem';
import {Observable} from 'rxjs';
import {ProblemService} from '../modules/problem/service/problem.service';

@Injectable({ providedIn: 'root' })
export class ProblemListResolve implements Resolve<Problem[]> {

  constructor(private service: ProblemService) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Problem[]> | Promise<Problem[]> | Problem[] {
    return this.service.findAll();
  }
}
