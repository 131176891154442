<div class="col-md-12"><h1>Badge</h1></div>
<mat-card class="col-md-6">
  <div *ngFor="let b of badges" class="row">
    <div class="col-md-2 my-2 text-nowrap">
      <i [matTooltip]="b.name" class="material-icons badgeIcon" style="color:{{b.color}}">{{b.icon}}</i>
      <i *ngIf="b.criteria[0].language" [matTooltip]="b.name" class="badgeLanguage {{getLanguageIcon(b.criteria[0].language)}}"></i>
    </div>
    <div class="col-md-2 my-2">
      <div>{{b.name}}</div>
    </div>
    <div class="col-md-8 my-2">
      <div>{{b.description}}</div>
    </div>
  </div>
</mat-card>
