import {Component, OnInit} from '@angular/core';
import {UserService} from '../../service/user.service';
import {FormControl, FormGroup} from '@angular/forms';
import {switchMap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EMPTY} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-user-my-profile',
  templateUrl: './user-my-profile.component.html',
  styleUrls: ['./user-my-profile.component.scss']
})

export class UserMyProfileComponent implements OnInit {

  private _currentUser;

  private _userdata = [];

  public formGroup: FormGroup = new FormGroup({
    username: new FormControl(),
    email: new FormControl(),
    oldpassword: new FormControl(),
    newpassword1: new FormControl(),
    newpassword2: new FormControl(),
    avatar: new FormControl()

  });

  get userdata() {
    return this._userdata;
  }

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this._currentUser = this.userService.getCurrentUser();

    console.log(this._currentUser.toJSON());
    console.log(this._currentUser.get('avatar'));
    this._userdata.push({
      id: this._currentUser.id,
      username: this._currentUser.getUsername(),
      email: this._currentUser.getEmail(),
      avatar: this._currentUser.get('avatar')
    });

    this.formGroup.patchValue({
      username: this._currentUser.getUsername(),
      email: this._currentUser.getEmail(),
      avatar: this._currentUser.get('avatar') ? this._currentUser.get('avatar') : ''
    }, { emitEvent: false });
  }

  public saveCurrentUser() {
    const formUsername = this.formGroup.get('username').value;
    const formEmail = this.formGroup.get('email').value;
    const formAvatar = this.formGroup.get('avatar').value;
    const oldpassword = this.formGroup.get('oldpassword').value;
    const newpassword1 = this.formGroup.get('newpassword1').value;
    const newpassword2 = this.formGroup.get('newpassword2').value;

    if (formUsername && formUsername !== '' &&
      formUsername !== this.userService.getCurrentUser().getUsername()) {
      this.userService.isUnusedUsername(formUsername).pipe(
        switchMap(result => {
          if (result.unique) {
            this.userService.getCurrentUser().setUsername(formUsername, null);
            return fromPromise(this.userService.getCurrentUser().save());
          } else {
            this.snackBar.open('Username già in uso!', null, {
              duration: 2000
            });
            return EMPTY;
          }
        })
      ).subscribe(result => {
        console.log(result);
        this.snackBar.open('Username cambiato in ' + formUsername + '!', null, {
          duration: 2000
        });
      });

    }


    if (this.formGroup.get('email').valid && formEmail !== '' && formEmail !== this.userService.getCurrentUser().getEmail()) {
      const checkEmail = this.userService.isUnusedEmail(formEmail).subscribe(result => {
        if (result.unique) {
          this.userService.getCurrentUser().setEmail(formEmail, null);
          this.userService.getCurrentUser().save();
          alert('Indirizzo email ' + formEmail + ' salvato!');
        } else {
          alert('Email già in uso!');
        }
      });
    }

    if (formAvatar && formAvatar !== '' && formAvatar !== this.userService.getCurrentUser().get('avatar')) {
      this.userService.getCurrentUser().set('avatar', formAvatar);
      this.userService.getCurrentUser().save();
      alert('Avatar salvato!');
    }

    if (newpassword1 && newpassword1 !== '' && newpassword1 === newpassword2) {

      const passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
      if (newpassword1.match(passRegex)) {
        this.userService.getCurrentUser().setPassword(newpassword1, null);
        this.userService.getCurrentUser().save();
        alert('Nuova password salvata!');
      } else {
        alert('Password troppo debole!');
      }
    }
  }
}
