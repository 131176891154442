<mat-card>
  <form [formGroup]="formGroup">
    <app-problem-form formControlName="problem"></app-problem-form>
    <div class="fab-container">
      <button mat-fab
              color="primary"
              type="submit"
              [disabled]="formGroup.invalid"
              (click)="saveProblem()" matTooltip="Save problem">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </form>
</mat-card>
