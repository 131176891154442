<div *ngFor="let r of results">
  <div class="row">
  <div class="col-md-10"><h1>{{count}} utenti hanno risolto "{{r.name}}"</h1>
  </div>
  <div class="col-md-2">
      <button mat-raised-button color="primary" [routerLink]="'/problems/view/' + r.problemId">Prova ancora</button>
  </div>
  </div>

  <mat-card class="col-md-12 user-list">
    <div *ngFor="let user of r.users" class="user-bubble">
      <div class=""><img *ngIf="user.avatar" src="{{user.avatar}}" alt="{{user.name}}"
                                                height="40" width="40"/></div>
      <div class="">{{user.name}}</div>
      <div *ngIf="user.solved" class="ok-icon">
        <span *ngFor="let lang of user.solvedLanguages" (click)="seeSolution(r.problemId, lang,  user.userId)"
              class="cursor-pointer">
           <i [matTooltip]="lang" class="{{getIcon(lang)}}"></i>
        </span>
      </div>
    </div>
  </mat-card>
</div>

<ng-template #resultDialog let-data>
  <h2 matDialogTitle>Soluzione del Problema</h2>
  <mat-dialog-content>
    <div class="dialog-code">
      <ngx-codemirror style="padding-right: 4px" *ngIf="data.source" [ngModel]="data.source"
                        [options]="{
                          lineNumbers: false,
                          theme: 'material',
                          mode: data.language,
                          readOnly: true }"
      ></ngx-codemirror>
      <ng-container *ngIf="data.message">
        <p>{{data.message}}</p>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button matDialogClose color="primary">Chiudi</button>
  </mat-dialog-actions>
</ng-template>
