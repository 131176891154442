<h2>Registrazione</h2>
<div [formGroup]="formGroup">
  <div class="col-md-6">
  <div class="md-col-6 row">
    <mat-form-field>
      <mat-label>Inserisci la tua email:</mat-label>
    <input matInput [type]="'email'" formControlName="email" email="true" required>
    </mat-form-field>
  </div>

  <div class="md-col-6 row">
    <mat-form-field>
      <mat-label>Scegli uno username:</mat-label>
      <input matInput formControlName="user" required>
    </mat-form-field>
  </div>

  <div class="md-col-6 row">
    <mat-form-field>
      <mat-label>Scegli una password</mat-label>
      <input matInput [type]="'password'" formControlName="password" required>
    </mat-form-field>
  </div>

  <div class="md-col-6 row">
    <mat-form-field>
      <mat-label>Conferma la password</mat-label>
      <input matInput [type]="'password'" formControlName="password2" required>
    </mat-form-field>
  </div>

  <div class="md-col-6 row">
    <p>ATTENZIONE: scegli una password robusta ma che NON utilizzi per altri sistemi.  Minimo: 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale.</p>
  </div>

  <div class="md-col-6 row">
    <h2 *ngIf="showErrorMessage">Ooops! Qualcosa non va con i dati che hai inserito...</h2>
  </div>

  <div class="md-col-6 row">
    <p><button mat-button color="primary" (click)="signup()">Registrati</button></p>
  </div>

  <div class="md-col-6 row">
    <p>Hai già un account? <button mat-button color="primary" (click)="login()">Accedi</button></p>
  </div>
  </div>
</div>
