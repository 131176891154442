import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {ParseServerModule} from '../parse-server/parse-server.module';
import {RouterModule} from '@angular/router';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ChipListModule} from '../chip-list/chip-list.module';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/python/python';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmDialogModule} from '../confirm-dialog/confirm-dialog.module';
import {BadgesComponent} from './page/badges/badges.component';
import {UsersBadgesComponent} from './page/users-badges/users-badges.component';

@NgModule({
  declarations: [
    BadgesComponent,
    UsersBadgesComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    ParseServerModule,
    RouterModule,
    CodemirrorModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    ChipListModule,
    MatDialogModule,
    ConfirmDialogModule
  ]
})
export class BadgeModule {
}
