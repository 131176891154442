<div [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12">
      <mat-form-field class="full-width">
        <mat-label>Title</mat-label>
        <input matInput required formControlName="name">
        <mat-error *ngIf="displayError('name', 'required')">Please provide a title</mat-error>
      </mat-form-field>
    </div>

  </div>

  <div class="row">
    <div class="col-md-6">
      <mat-form-field class="full-width">
        <mat-label>Level</mat-label>
        <mat-select formControlName="level" required>
          <mat-option value="easy">Easy</mat-option>
          <mat-option value="medium">Medium</mat-option>
          <mat-option value="hard">Hard</mat-option>
        </mat-select>
        <mat-error *ngIf="displayError('level', 'required')">Please provide a level</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <app-chip-list [choices]="tagChoices" formControlName="tags"></app-chip-list>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6">
      <mat-form-field class="full-width">
        <mat-label>Short version (preview)</mat-label>
        <textarea [rows]="15" matInput required formControlName="preview"></textarea>
        <mat-error *ngIf="displayError('preview', 'required')">Please provide a preview</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-6">
      <mat-form-field class="full-width">
        <mat-label>Full problem text</mat-label>
        <textarea [rows]="15" matInput formControlName="body" required></textarea>
        <mat-error *ngIf="displayError('subject', 'required')">Please provide a body text</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="col-xl-6">
    <h2>Function Arguments</h2>
    <ul formArrayName="fnArgs" class="list-unstyled">
      <li *ngFor="let fnArg of formGroup.controls.fnArgs.value; let i = index; trackBy: trackByFnArg">
        <app-problem-fn-arg-form (delete)="removeFnArg(i)" [formControlName]="i.toString()"></app-problem-fn-arg-form>
      </li>
    </ul>
    <mat-error *ngIf="displayError('fnArgs', 'minlength')">Please provide at least 1 argument</mat-error>
  </div>
  <button mat-mini-fab color="primary" (click)="addFnArg()" matTooltip="Add a new argument">
    <mat-icon>add</mat-icon>
  </button>

  <div class="col-xl-12">
    <h2>Manage tests</h2>
    <ul formArrayName="tests" class="list-unstyled">
      <li *ngFor="let test of formGroup.controls.tests.value; let i = index; trackBy: trackByFnTest">
        <app-problem-test-form (delete)="removeTest(i)"
                               [formControlName]="i.toString()">
        </app-problem-test-form>
      </li>
    </ul>
    <mat-error *ngIf="displayError('tests', 'minlength')">Please provide at least 3 tests</mat-error>
  </div>
  <button mat-mini-fab color="primary" (click)="addTest()" matTooltip="Add a new test">
    <mat-icon>add</mat-icon>
  </button>
</div>

