import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ProblemTest} from '../../model/problem-test';

@Component({
  selector: 'app-problem-test-form',
  templateUrl: './problem-test-form.component.html',
  styleUrls: ['./problem-test-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProblemTestFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
        useExisting: forwardRef(() => ProblemTestFormComponent),
  multi: true
}
  ]
})
export class ProblemTestFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  public formGroup: FormGroup;

  private _subscriptions: Subscription[] = [];

  constructor() {
    this.formGroup = new FormGroup({
      id: new FormControl(),
      name: new FormControl('', Validators.required),
      input: new FormArray([], Validators.required),
      output: new FormControl('', Validators.required),
      visible: new FormControl(false)
    });
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this.formGroup.valueChanges.subscribe(value => {
        this._onTouch(value);
        this._onChange(value);
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onDelete() {
    this.delete.emit();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: ProblemTest): void {
    if (value) {
      this.formGroup.patchValue(value, {emitEvent: false});
      (this.formGroup.get('input') as FormArray).clear();
      value.input.forEach(inp => {
        (this.formGroup.get('input') as FormArray).push(new FormControl(inp));
      });
    }

    if (this.formGroup.invalid) {
      this._onChange(null);
    }
  }

  private _onChange(value: ProblemTest) {
  }

  private _onTouch(value: ProblemTest) {
  }

  trackByIndex(index: number): number {
    return index;
  }

  items() {
    return (this.formGroup.get('input') as FormArray).controls;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const someEmpty: boolean = (this.formGroup.getRawValue().input as any[]).some(val => !val);
    if (!(this.formGroup.get('input') as FormArray).length) {
      return { inputLength: true };
    } else if (someEmpty) {
      return { someEmpty: true };
    }
    return null;
  }
}
