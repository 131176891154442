import {ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../modules/user/service/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
  constructor(private userService: UserService, private route: Router) {}

  logout() {
    this.userService.logout();
    this.route.navigateByUrl('/login');
  }

  help() {
    window.open('/assets/i3sapiens - quick guide.pdf', 'i3sapiens-help')
  }

  getCurrentUser() {
    return this.userService.getCurrentUser();
  }
}
