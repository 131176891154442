import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Problem} from '../../model/problem';
import {UserService} from '../../../user/service/user.service';
import {ProblemService} from '../../service/problem.service';


@Component({
  selector: 'app-problem-list',
  templateUrl: './problem-list.component.html',
  styleUrls: ['./problem-list.component.scss']
})
export class ProblemListComponent implements OnInit {

  private _problems: Problem[] = [];
  public get problems(): Problem[] {
    return this._problems;
  }

  public isAdmin: boolean;

  private _filter: string;

  set filter(filter) {
    this._filter = filter;
  }

  get filter(): string {
    return this._filter;
  }

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private problemService: ProblemService) { }

  ngOnInit(): void {
    this.route.data.subscribe(result => {
      this._problems = result.problems as Problem[];
    });
    this.userService.getRole().subscribe( result => {
      this.isAdmin = result.Administrator;
    });
  }

  loadProblemList(): void {
    this.problemService.findAll(this.filter).subscribe(result => {
      this._problems = result;
    });
  }

  getLevelIcons(level: string){
    switch (level){
      case 'easy': return '🤗️';
      case 'medium': return '🤔️';
      case 'hard': return '🤯️';
      default: return  '🤔️';
    }
  }
}
