<div [formGroup]="formGroup">
  <div class="col-md-9" style="display:flex; clear: both;">
    <div class="mat-card col-md-4">
      <h2>Login</h2>
      <mat-form-field class="full-width">
        <mat-label>Username</mat-label>
        <input matInput formControlName="user">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Password</mat-label>
        <input matInput [type]="'password'" formControlName="password">
      </mat-form-field>
      <ng-container *ngIf="showErrorMessage">
        <h4>Username o password errati, o email non confermata</h4>
        <p>I valori sono <i>case sensitive</i></p>
      </ng-container>
      <p>
        <button mat-button color="primary" (click)="login()">Accedi</button>
      </p>
    </div>
    <div class="col-md-1"></div>
    <div class="mat-card col-md-4">
      <h2>&nbsp;</h2>
      <p>Password dimenticata?</p>
      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput formControlName="emailreset">
      </mat-form-field>
      <button mat-button color="primary" (click)="passwordreset()">Reset password</button>
    </div>
  </div>

  <div class="col-md-9" style="display:flex; clear: both; margin-top:2em;">
    <div class="col-md-9 mat-card" style="display:block; clear: both;">
      <p>Non hai un account?
        <button mat-button color="primary" (click)="signup()">Registrati</button>
      </p>
    </div>
  </div>
</div>
