<div class="col-md-12"><h1>Badge</h1></div>
<mat-card class="col-md-6">
  <div *ngFor="let u of usersBadges" class="row">
    <div class="col-md-2 my-2">
      <div class="col-md-1 center-content"><img *ngIf="u.user.avatar" src="{{u.user.avatar}}" alt="{{u.user.username}}"
        height="40" width="40"/></div>
      <div class="col-md-3 center-content">{{u.user.username}}</div>
    </div>
    <div class="col-md-10 my-2">
      <span *ngFor="let b of u.badges" class="text-nowrap">
        <i [matTooltip]="b.name" class="material-icons badgeIcon" style="color:{{b.color}}">{{b.icon}}</i>
        <i *ngIf="b.criteria[0].language" [matTooltip]="b.name" class="badgeLanguage {{getLanguageIcon(b.criteria[0].language)}}"></i>
      </span>
    </div>
  </div>
</mat-card>
