import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PageNotFoundComponent} from './component/page-not-found/page-not-found.component';
import {ProblemModule} from './modules/problem/problem.module';
import {AppHeaderComponent} from './component/app-header/app-header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AppLoginComponent} from './component/app-login/app-login.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {LoaderModule} from './modules/loader/loader.module';
import {AppSignupComponent} from './component/app-signup/app-signup.component';
import {AppFooterComponent} from './component/app-footer/app-footer.component';
import {MatCardModule} from '@angular/material/card';
import {UserModule} from './modules/user/user.module';
import {BadgeModule} from './modules/badge/badge.module';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    AppHeaderComponent,
    AppLoginComponent,
    AppSignupComponent,
    AppFooterComponent,
  ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      ProblemModule,
      MatToolbarModule,
      MatFormFieldModule,
      MatIconModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatInputModule,
      MatDialogModule,
      MatCardModule,
      LoaderModule,
      UserModule,
      BadgeModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
