<div class="col-md-8">
  <mat-form-field class="full-width">
    <mat-label>Filter</mat-label>
    <input matInput [(ngModel)]="filter" (ngModelChange)="loadProblemList()">
  </mat-form-field>
</div>
<div class="row align-items-center">
  <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngFor="let problem of problems">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="header-avatar">
          <!--<i [matTooltip]="problem.subject" class="{{getIcon(problem.subject)}}"></i>-->
          <div style="padding-top:5px" [matTooltip]="problem.level">{{getLevelIcons(problem.level)}}</div>
        </div>

        <mat-card-title>{{ problem.name }}</mat-card-title>
        <mat-card-subtitle>{{ problem.createdAt | date }}</mat-card-subtitle>
        <mat-icon [routerLink]="'/problems/results/' + problem.objectId"
                  class="header-icon cursor-pointer" matTooltip="Who solved it?">group</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <p>{{ problem.preview }}</p>
      </mat-card-content>
      <mat-card-actions class="text-right">
        <button mat-raised-button [routerLink]="'/problems/edit/' + problem.objectId" *ngIf="isAdmin">Edit</button>
        <button mat-raised-button color="primary" [routerLink]="'/problems/view/' + problem.objectId">Solve</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<button mat-raised-button [routerLink]="'/problems/edit/'" *ngIf="isAdmin"><mat-icon>add</mat-icon></button>
