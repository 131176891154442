<div class="col-md-12"><h1>I tuoi dati</h1></div>

<div [formGroup]="formGroup">
  <mat-card class="col-md-8">
    <div *ngFor="let d of userdata">
    <!--  <div class="row">
        <div class="col-md-3">ID</div>
        <div class="col-md-5">{{d.id}}</div>
    </div> -->
      <mat-form-field class="full-width">
        <mat-label>Username <i>(case sensitive)</i></mat-label>
        <input matInput required formControlName="username">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput required formControlName="email">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Avatar (URL di immagine quadrata, es. da GitHub)</mat-label>
        <input matInput formControlName="avatar">
      </mat-form-field>
      <p>
        <img *ngIf="d.avatar" src="{{d.avatar}}" alt="{{d.username}}" height="100" width="100" style="margin:8px"/>
      </p>
      <!--<div class="row">

        <div class="col-md-3">Username <i>(case sensitive)</i></div>
        <div class="col-md-5"><input matInput formControlName="username" name="username" placeholder="{{d.username}}"></div>
      </div>-->

      <h3><strong>Cambio password</strong></h3>

      <p>Scegli una password sicura ma che NON usi per altri accessi. Minimo: 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale</p>

      <mat-form-field class="full-width">
        <mat-label>Nuova password</mat-label>
        <input matInput [type]="'password'" formControlName="newpassword1" autocomplete="new-password">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Ripeti password</mat-label>
        <input matInput [type]="'password'" formControlName="newpassword2">
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="saveCurrentUser()">
        <span>Salva</span>
      </button>

  </div>
  </mat-card>
</div>
