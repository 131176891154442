import {Component, OnInit} from '@angular/core';
import {BadgeService} from '../../service/badge.service';
import {orderBy} from 'lodash';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {
  private _badges;

  get badges() {
    return this._badges;
  }

  constructor(private service: BadgeService) {
  }

  ngOnInit(): void {
    this.service.findAll().subscribe(badges => {
      this._badges = orderBy(badges, ['icon', 'criteria[0].language', 'color'], ['asc', 'asc', 'desc']);
    });
  }

  getLanguageIcon(type: string): string {
    switch (type) {
      case 'java':
        return 'devicon-java-plain javaColor';
      case 'nodejs':
        return 'devicon-javascript-plain javascriptColor';
      case 'python3':
        return 'devicon-python-plain pythonColor';
      default:
        return 'devicon-linux-plain';
    }
  }
}
