<mat-form-field class="full-width">
  <mat-chip-list #chipList aria-label="Tags">
    <mat-chip
      *ngFor="let fruit of tagList"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(fruit)">
      {{fruit}}
      <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
    </mat-chip>
    <input
      matInput
      placeholder="Tags"
      [formControl]="tagsInputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let choice of filteredChoices | async" [value]="choice">
      {{choice}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
