<mat-card [formGroup]="formGroup">
  <div *ngIf="value">
    <h1>{{ value.name }}</h1>
    <dl>
      <dt>Level</dt>
      <dd>{{ value.level }}</dd>
    </dl>
    <h2>Problem text:</h2>
    <div><pre style="white-space: pre-wrap;       /* css-3 */
      white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
      word-wrap: break-word;       /* Internet Explorer 5.5+ */">{{ value.body }}</pre>
    </div>

    <h2>Your solution:</h2>
    <mat-form-field class="full-width">
      <mat-label>Language</mat-label>
      <mat-select formControlName="languageId" required>
        <mat-option value="java">Java</mat-option>
        <mat-option value="nodejs">JavaScript</mat-option>
        <mat-option value="python3">Python</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="formGroup.get('languageId').value as languageId">
      <ngx-codemirror #codeMirror
                      formControlName="sourceCode"
                      [options]="{
          fullScreen: true,
          viewportMargin: POSITIVE_INFINITY,
          lineNumbers: true,
          theme: 'material',
          mode: getMode(languageId)
        }"></ngx-codemirror>
      <button mat-button (click)="doFullScreen()">🔍 Full Screen</button>
    </ng-container>

    <h2>Tests:</h2>
    <mat-list>
      <mat-list-item>
        <div class="row col-md-12 testCase test-titles">
          <div class="col-md-1">#</div>
          <div class="col-md-2">Test case</div>
          <div class="col-md-4">Input</div>
          <div class="col-md-4">Expected output</div>
          <div class="col-md-1">Result</div>
        </div>
      </mat-list-item>
      <ng-container *ngFor="let test of visibleTests; index as i">
        <mat-list-item>
          <div class="row col-md-12 testCase">
            <div class="col-md-1">{{ i + 1 }}</div>
            <div class="col-md-2">{{ test.name }}</div>
            <pre class="col-md-4">{{ test.input }}</pre>
            <pre class="col-md-4">{{ test.output }}</pre>
            <div class="col-md-1">
              <span class="play-icon" (click)="runTest(test.id)" matTooltip="Run test case">{{getRunIcon(test)}}</span>
              <span [matTooltip]="test.success===true ? 'OK!' : test.success===false ? 'KO' : ''"
                    class="ok-icon">{{ getSuccessIcon(test)}}</span>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="[true,false].includes(test.success)" style="height:128px;">
          <div style="height:100%"
               [ngClass]="['row', 'col-md-12', 'testCase', 'row-result',
                          test.success ? 'solution-ok' : 'solution-ko']"
          >
            <div class="col-md-1 lblerr">Debug output</div>
            <pre class="col-md-5 stderr">{{ testResults[test.id].stderr }}</pre>
            <div class="col-md-1 lblout">Your output</div>
            <pre class="col-md-5 stdout">{{ testResults[test.id].stdout }}</pre>
          </div>
        </mat-list-item>
      </ng-container>
    </mat-list>
    <p>
      <button mat-button type="submit" color="primary" (click)="runTest()">
        <span class="play-icon">⏩</span> Run all visible test cases
      </button>
    </p>
    <p>
      <button mat-button type="submit" color="primary" (click)="confirm()">
        <span class="play-icon">💾</span> Submit your solution
      </button>
      (Additional secret test cases will be performed. If your code does not pass some tests, you'll be able to try
      again later.)
    </p>
  </div>
</mat-card>
