<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  {{ data.content }}
</div>
<div *ngFor="let t of data.tests" style="margin-top: 2px">
  {{t.success?'✅':'❌'}} {{t.name}}
  <div *ngIf="t.success"><em>OK</em></div>
  <div [ngSwitch]="t.outcome" *ngIf="!t.success">
    <em *ngSwitchCase="11"> Compilation error </em>
    <em *ngSwitchCase="12"> Runtime error </em>
    <em *ngSwitchCase="13"> Time limit exceeded </em>
    <em *ngSwitchCase="15"> Failed test </em>
    <em *ngSwitchCase="17"> Memory limit exceeded </em>
    <em *ngSwitchCase="19"> Illegal system call </em>
    <em *ngSwitchCase="20"> Internal error </em>
    <em *ngSwitchCase="21"> Server overload </em>
  </div>
</div>
