import {Component, OnInit} from '@angular/core';
import {ProblemService} from "../../service/problem.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private service: ProblemService
  ) {}

  ngOnInit(): void {}

}
