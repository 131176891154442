import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormGroup, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ProblemFnArg} from '../../model/problem-fn-arg';

@Component({
  selector: 'app-problem-fn-arg-form',
  templateUrl: './problem-fn-arg-form.component.html',
  styleUrls: ['./problem-fn-arg-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProblemFnArgFormComponent),
      multi: true
    }
  ]
})
export class ProblemFnArgFormComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    name: new FormControl('', Validators.required)
  });

  private _subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit(): void {
    this._subscriptions.push(
      this.formGroup.valueChanges.subscribe(value => {
        this._onTouch(value);
        this._onChange(value);
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onDelete() {
    this.delete.emit();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: ProblemFnArg): void {
    if (value) {
      this.formGroup.setValue(value, {emitEvent: false});
    }
  }

  private _onChange(value: ProblemFnArg) { }

  private _onTouch(value: ProblemFnArg) {}

}
