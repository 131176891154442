import { Component, OnInit } from '@angular/core';
import {LoaderService} from '../service/loader.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        opacity: 1,
        display: 'flex'
      })),
      state('closed', style({
        opacity: 0,
        display: 'none'
      })),
      transition('open <=> closed', [
        animate('0.2s'),
      ])
    ]),
  ],
})
export class LoaderComponent {

  public get isLoading(): boolean {
    return this.loader.isLoading;
  }
  constructor(
    private loader: LoaderService
  ) { }

}
