import {Injectable} from '@angular/core';
import {ParseServerService} from '../../parse-server/service/parse-server.service';
import {ParseApi} from '../../parse-server/parse-api';
import {Badge} from '../model/badge';
import {LoaderService} from '../../loader/service/loader.service';
import {Observable} from 'rxjs';
import {UserBadges} from '../model/user-badges';

@Injectable({
  providedIn: 'root'
})
export class BadgeService extends ParseApi<Badge> {

  protected _parseClass = 'Badges';

  constructor(
    protected parse: ParseServerService,
    protected loader: LoaderService
  ) {
    super(parse, loader);
  }

  public getUsersBadges(): Observable<UserBadges[]> {
    return this.parse.run('usersBadges', {});
  }
}
